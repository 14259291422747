import {makeStyles} from "@mui/styles";
import {Grid} from "@mui/material";

import {Typography} from "@mui/material";

const useStyle = makeStyles((theme) => ({
  pageRoot: {
    height: "100%",
    minHeight: "100vh",
    width: "100%",
    background: theme.customColors.sectionBg,
    paddingTop: theme.spacing(10),
  },
  pageContentRoot: {
    height: "100%",
    marginTop: "0 !important",
    padding: theme.spacing(2),
  },
  pageTitleWrapper: {
    marginBottom: theme.spacing(4),
  },
  pageTitle: {
    width:'250px',
    fontSize:'64px',
    [theme.breakpoints.down("md")]: {
      width:'173px',
      fontSize:'32px !important',
    },
    border: `4px solid ${theme.customColors.secondary}`,
    marginLeft: "auto !important",
    marginRight: "auto !important",
    padding: theme.spacing(2),
    // fontSize: "4rem !important",
    background: theme.customColors.textBg,
    textShadow: theme.customColors.textShadow,
    paddingLeft: "22px",
    paddingRight: "22px",
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    boxShadow: theme.customColors.textBorderShadow,
  },
  sectionWrapper: {
    background: `rgba(0,0,0, .7)`,
    display: "block",
    borderBottom: `3px solid ${theme.customColors.secondary}`,
    marginTop: `${theme.spacing(4)} !important`,
    [theme.breakpoints.down("md")]: {
      marginLeft:'16px !important'
    },
  },
  sectionTitle: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
    padding: theme.spacing(2),
    fontSize: "1rem !important",
    background: theme.customColors.textBg,
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    textAlign: "center",
  },
  sectionText: {
    paddingRight:'13px !important',
    paddingBottom:'20px',
    [theme.breakpoints.down("md")]: {
    paddingBottom:'20px !important'
    },
  
  },
  sectionText2: {
    paddingRight:'13px !important',
   
  
  },
  sectionText3: {
    paddingRight:'13px !important',
   
  
  },
}));

const Benefits = () => {
  const classes = useStyle();

  return (
    <Grid
      classes={{
        root: classes.pageRoot,
      }}
      id='high-club-benefits'
    >
      <Grid
        container
        spacing={2}
        justifyContent='center'
        classes={{
          root: classes.pageContentRoot,
        }}
      >
        <Grid item xs={12} classes={{root: classes.pageTitleWrapper}}>
          <Typography
            variant='h3'
            color='primary'
            align='center'
            classes={{root: classes.pageTitle}}
          >
            Benefits
          </Typography>
        </Grid>

        <Grid
          item
          xs={10}
          sm={8}
          classes={{
            root: classes.sectionWrapper,
          }}
        >
          <Typography classes={{root: classes.sectionTitle}}>
            Ultra High Club
          </Typography>

          <Typography
            color='primary'
            align='left'
            classes={{root: classes.sectionText}}
          >
            &#8226; Dao VIP Concierge App <br />
            &#8226; DAO is to allow people to use UHD in the real world, eg.
            buying yacht, private jets, clubs etc. UHD can also be used as a
            currency at UHC's various merchant outlets without having to carry
            their cash or card, which can be lost or stolen
            <br />
          </Typography>
        </Grid>

        <Grid
          item
          xs={10}
          sm={8}
          classes={{
            root: classes.sectionWrapper,
          }}
        >
          <Typography classes={{root: classes.sectionTitle}}>
            ULTRA HIGH FUND CREATION
          </Typography>

          <Typography
            color='primary'
            align='left'
            classes={{root: classes.sectionText2}}
          >
            &#8226; Our members will choose the projects we are going to invest
            in (DAO). <br />
            &#8226; Our members will also have the possibility to invest. This
            will thus increase our UHD coin value. <br />
            &#8226; Every 6 months, we will be investing $50,000 to $100,000 USD
            each in 2 of our HOLDERS' projects. <br />
            <br />
          </Typography>
        </Grid>

        <Grid
          item
          xs={10}
          sm={8}
          classes={{
            root: classes.sectionWrapper,
          }}
        >
          <Typography classes={{root: classes.sectionTitle}}>
            ULTRA HIGH CHARITY
          </Typography>

          <Typography
            color='primary'
            align='left'
            classes={{root: classes.sectionText3}}
          >
            &#8226; We believe in abundance and the responsibility to give back
            to others. <br />
            &#8226; Our first project involves providing over $150K to build
            source of income for the poor in Philippines with Crossroads <br />
            &#8226; We are setting a model and precedence of community-driven
            charity that will change the world. <br />
            <br />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Benefits;
