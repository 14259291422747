import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme: any) => ({
  root: {
    width: "100%",
    position: "absolute",
    height: "2px",
    marginBottom: "6px",
    background: theme.customColors.sectionDivider,
  },
}));

const PageDivider = () => <div className={useStyle().root} />;

export default PageDivider;
