import { makeStyles } from "@mui/styles";
import { Grid, Hidden } from "@mui/material";
import { Typography } from "@material-ui/core";

import UltraHigh from "../../assets/images/ultra-high-logo-dark.png";
import CharacterBoy from "../../assets/images/character-boy.png";
import CharacterGirl from "../../assets/images/character-girl.png";

const useStyle = makeStyles((theme: any) => ({
  pageRoot: {
    height: "1000px",
    width: "100%",
    position: "relative",
    
    [theme.breakpoints.down("md")]: {
      height: "700px",
      objectFit:'contain'
    },
  },
  ultraHighLogo: {
    maxWidth: "350px",
    [theme.breakpoints.down("md")]: {
      maxHeight: "200px",
      marginBottom: theme.spacing(3),
    },
  },
  bgWrapper: {
    height: "1000px",
    width: "100%",
    background: theme.customColors.pageBg,
    position: "absolute",
    zIndex: -1,
   
  },
  bgInner: {
    width: "100%",
    height: "90%",
    position: "absolute",
    bottom: 0,
  },
  girlBg: {
    width: "100%",
    height: "100%",
  },
  boyBg: {
    width: "100%",
    height: "100%",
  },
  pageContent: {
    position: "absolute",
    zIndex: 1,
    paddingTop: theme.spacing(10),
  },
  pageTitleWrapper: {
    marginTop: theme.spacing(5),
  },
  pageTitle: {
    // width: "max-content",
    marginLeft: "auto !important",
    marginRight: "auto !important",
    padding: theme.spacing(2),
    fontSize: "4rem !important",
    background: theme.customColors.textBg,
    textShadow: theme.customColors.textShadow,
    paddingLeft: "22px",
    paddingRight: "22px",
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    [theme.breakpoints.down("lg")]: {
      fontSize: "48px !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "48px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem !important",
    },
    
  },
}));

const About = () => {
  const classes = useStyle();

  return (
   <div className="aboutResponsive">
      <Grid classes={{ root: classes.pageRoot }} id="high-club-about">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        classes={{ root: classes.bgWrapper }}
      >
       <Hidden mdDown>
       <Grid container classes={{ root: classes.bgInner }}>
          <Grid item xs={6}>
            <img
              src={CharacterGirl}
              alt="Ultra High Text"
              width="350"
              className={classes.girlBg}
            />
          </Grid>

          <Grid item xs={6}>
            <img
              src={CharacterBoy}
              alt="Ultra High Text"
              width="350"
              className={classes.boyBg}
            />
          </Grid>
        </Grid>
       </Hidden>
      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        classes={{ root: classes.pageContent }}
      >
        <Grid item xs={12} classes={{ root: classes.pageTitleWrapper }}>
          <Typography
            variant="h3"
            color="primary"
            align="center"
            classes={{ root: classes.pageTitle }}
          >
            About us
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <img
            src={UltraHigh}
            className={classes.ultraHighLogo}
            alt="Ultra High Logo"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography className='aboutText' color="primary" align="center">
            Welcome to our community. <br />
            Becoming an ULTRA HIGH owner <br />
            introduces you to a PRIVATE ULTRA HIGH CLUB <br />
            of the ULTRA HIGH network.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
   </div>
  );
};

export default About;
