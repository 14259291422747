import { makeStyles } from "@mui/styles";
import Welcome from "../welcome/Welcome";
import About from "../about/about";
import Works from "../works/Works";
import RoadMap from "../roadMap/RoadMap";
import Benefits from "../benefits/Benefits";
import TheTeam from "../theTeam/TheTeam";
import Footer from "../footer/Footer";
import MainNavBar from "../mainNavBar/MainNavBar";
import PageDivider from "../pageDivider/PageDivider";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
}));

const LandingPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MainNavBar />
      <Welcome />
      <PageDivider />
      <About />
      <PageDivider />
      <Benefits />
      <PageDivider />
      <Works />
      <PageDivider />
      <RoadMap />
      <PageDivider />
      <TheTeam />
      <PageDivider />
      <Footer />
      <PageDivider />
    </div>
  );
};

export default LandingPage;
