import { ThemeProvider } from "@mui/material/styles";

import LandingPage from "./components/langingPage/LandingPage";
import "./App.scss";
import { theme } from "./theme/theme";
import RoutesComponent from "./routes";

const App = () => {
  return (
    <ThemeProvider theme={theme} >
      <div className='body'>
      <RoutesComponent />
      </div>
     
      {/* <LandingPage /> */}
    </ThemeProvider>
  );
};

export default App;
