import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Grid, Hidden, Stack } from '@mui/material';

import { Typography } from '@mui/material';

import BgLeft from '../../assets/images/road-map-bg-left.png';
import BgRight from '../../assets/images/road-map-bg-right.png';
import UltraHigh from '../../assets/images/ultra-high-logo-dark.png';

const useStyle = makeStyles((theme) => ({
  pageRoot: {
    height: '100vh',
    minHeight: '100vh',
    width: '100%',
    position: 'relative',
  },

  ultraHighBgWrapper: {
    height: '100%',
    minHeight: '100vh',
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
  },
  ultraHighBg: {
    position: 'absolute',
    width: '100%',
    height: '400px',
    top: '35%',
    backgroundImage: `url(${UltraHigh})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  pageContent: {
    position: 'absolute',
    width: '100%',
  },
  pageTitle: {
    width: 'max-content',
    border: `4px solid ${theme.customColors.secondary}`,
    margin: 'auto',
    padding: theme.spacing(2),
    fontSize: '4rem !important',
    background: theme.customColors.secondary,
    textShadow: theme.customColors.textShadow,
    paddingLeft: '22px',
    paddingRight: '22px',
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
    boxShadow: theme.customColors.textBorderShadow,
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    marginTop: `${theme.spacing(10)} !important`,
    [theme.breakpoints.down('md')]: {
      fontSize: '32px !important',
      width: '173px !Important',
      marginBottom:'30px !important'
    },
  },
  phasesWrapper: {
    height: '100%',
    width: '90%',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    position: 'relative',
  },
  phasesInner: {
    width: '100%',
    minHeight: '100px',
    display: 'flex',
    justifyContent: 'center',
  },
  phasesLeftContent: {
    borderRight: `2px solid ${theme.customColors.secondary}`,
  },
  phasesRightContent: {
    borderLeft: `2px solid ${theme.customColors.secondary}`,
  },
  leftPhaseTitleWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
   
  },
  rightPhaseTitleWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  phaseTitle: {
    margin: 'auto',
    width:'100% !important',
    fontSize: '3rem !important',
    background: theme.customColors.secondary,
    textShadow: theme.customColors.textShadow,
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem !important',
    },
  },
  phaseOne: {
    marginTop: `${theme.spacing(4)} !important`,
    [theme.breakpoints.down('md')]: {
      marginTop: `${theme.spacing(0)} !important`,
    },
  },
  phaseTwo: {
    marginTop: `${theme.spacing(10)} !important`,
    [theme.breakpoints.down('md')]: {
      marginTop: `${theme.spacing(0)} !important`,
    },
  },
  leftPhaseLine: {
    width: '30%',
    height: '4px',
    backgroundColor: `${theme.customColors.secondary}`,
    position: 'relative',
    top: '-28px',
    [theme.breakpoints.down('md')]: {
      top: '-18px',
    },
  },
  rightPhaseLine: {
    width: '30%',
    height: '4px',
    backgroundColor: `${theme.customColors.secondary}`,
    position: 'relative',
    top: '-14px',
    [theme.breakpoints.down('md')]: {
      top: '-10px',
    },
  },
  leftPhaseText: {
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(2),
    },
  },
  rightPhaseText: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(2),
    },
  },
  phaseFour: {
    position: 'relative',
    top: '-28px',
    [theme.breakpoints.down('md')]: {
      top: '-20px',
    },
  },
  customBgWrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  customBgLeft: {
    width: '50%',
    height: '100%',
    backgroundImage: `url(${BgLeft})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left bottom',
    zIndex: 10,
  },
  customBgRight: {
    width: '50%',
    height: '100%',
    backgroundImage: `url(${BgRight})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom',
    zIndex: 10,
  },
  customGradientBg: {
    background: theme.customColors.roadMapBg,
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: -10,
  },
  contentWrapper: {
    width: '100%',
    zIndex: 11,
  },
  contentFit:{
    width:'100%',
    height:'100%',
    
  },
}));

const RoadMap = () => {
  const classes = useStyle();
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    const resizeContainer = () => {
      const height = Math.max(
        document.getElementById('roadMapPageContent')?.clientHeight,
        document.getElementById('contentWrapper')?.clientHeight
      );
      let maxHeight = height > window.outerHeight ? height : window.outerHeight;
      setContentHeight(maxHeight);
      document.getElementById(
        'high-club-road-map'
      ).style.height = `${maxHeight}px`;
      document.getElementById(
        'road-map-custom-bg'
      ).style.height = `${maxHeight}px`;
      document.getElementById(
        'roadMapPageContent'
      ).style.height = `${maxHeight}px`;
    };

    window.onload = resizeContainer;
    resizeContainer();
  }, [contentHeight]);

  return (
    <div  id='high-club-road-map' className={classes.contentFit}>
    <Grid classes={{ root: classes.pageRoot }}>
      <div className={classes.customBgWrapper} id='road-map-custom-bg'>
        <div className={classes.customGradientBg}></div>
        <div className={classes.ultraHighBg}></div>
        <div className={classes.customBgLeft}></div>
        <div className={classes.customBgRight}></div>
      </div>
      <Grid
        justifyContent='center'
        alignItems='center'
        classes={{ root: classes.ultraHighBgWrapper }}
      >
        <div className={classes.ultraHighBg}></div>
      </Grid>

      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        classes={{ root: classes.pageContent }}
        id='roadMapPageContent'
      >
        <>
          {/* <div className={classes.customBgWrapper} id="road-map-custom-bg">
            <div className={classes.customGradientBg}></div>
            <div className={classes.ultraHighBg}></div>
            <div className={classes.customBgLeft}></div>
            <div className={classes.customBgRight}></div>
          </div> */}

          <div className={classes.contentWrapper} id='contentWrapper'>
            <Hidden mdDown>
              <Grid item xs={12}>
                <Typography
                  variant='h3'
                  color='primary'
                  align='center'
                  classes={{ root: classes.pageTitle }}
                >
                  Road Map
                </Typography>
              </Grid>

              <Grid item xs={12} classes={{ root: classes.phasesWrapper }}>
                <Grid container classes={{ root: classes.phasesInner }}>
                  <Grid
                    item
                    xs={6}
                    classes={{ root: classes.phasesLeftContent }}
                  >
                    {/* phase 2 */}
                    <div className={classes.leftPhaseTitleWrapper}>
                      <Typography
                        variant='h3'
                        color='primary'
                        align='center'
                        classes={{
                          root: clsx(classes.phaseTitle, classes.phaseTwo),
                        }}
                      >
                        phase 2
                      </Typography>
                      <div className={classes.leftPhaseLine}></div>
                    </div>

                    <Typography
                      variant='subtitle1'
                      color='primary'
                      align='left'
                      classes={{ root: classes.leftPhaseText }}
                    >
                      - Welcome Party In Dubai Or Maldives Or Bali Or Thailand{' '}
                      <br />- Holders get to attend exclusive party in own
                      country <br />- Onboarding more Merchants for UHC members
                      to patronize <br />
                      - Commencing of Frames &amp; Stake poll <br />
                      - Listing of UHD on various exchanges <br />
                      - Creating the DAO [Concierge APP] <br />
                      - 5,000 members of UHD <br />
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    classes={{ root: classes.phasesRightContent }}
                  >
                    <div className={classes.rightPhaseTitleWrapper}>
                      <div className={classes.rightPhaseLine}></div>
                      <Typography
                        variant='h3'
                        color='primary'
                        align='center'
                        classes={{
                          root: clsx(classes.phaseTitle, classes.phaseOne),
                        }}
                      >
                        phase 1
                      </Typography>
                    </div>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                      align='left'
                      classes={{ root: classes.rightPhaseText }}
                    >
                      - Website + Social Media Launch <br />- NFT Minting,
                      Wave-1 of 888 UHN <br />- Release of Wave-2 &amp; Wave-3
                      of UHN Minting <br />
                      - UHD Launch (ETH) <br />
                      - Uniswap <br />
                      - Announce list of Merchants partnering UHC in Singapore
                      &amp; Dubai &amp; Indonesia <br />
                      - 50k members (Discord) <br />
                      - 10k telegram (UHC) <br />
                      - 1000 holders (UHD) <br />
                    </Typography>

                    <div className={classes.rightPhaseTitleWrapper}>
                      <div className={classes.rightPhaseLine}></div>
                      <Typography
                        variant='h3'
                        color='primary'
                        align='center'
                        classes={{
                          root: clsx(classes.phaseTitle, classes.phaseOne),
                        }}
                      >
                        phase 3
                      </Typography>
                    </div>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                      align='left'
                      classes={{ root: classes.rightPhaseText }}
                    >
                      - Launch of Ultra High Metaverse [UHM] <br />- Increasing
                      number of merchants around Asia + Dubai <br />
                      - Charity <br />
                      - Fund Creation <br />
                      - Holders of UHD 10,000 by 2022 <br />
                    </Typography>
                  </Grid>

                  <Grid container classes={{ root: classes.phaseFour }}>
                    <Grid item xs={6}>
                      {/* phase 4 */}
                      <div className={classes.leftPhaseTitleWrapper}>
                        <Typography
                          variant='h3'
                          color='primary'
                          align='center'
                          classes={{
                            root: clsx(classes.phaseTitle),
                          }}
                        >
                          phase 4
                        </Typography>
                        <div className={classes.leftPhaseLine}></div>
                      </div>

                      <Typography
                        variant='subtitle1'
                        color='primary'
                        align='left'
                        classes={{ root: classes.leftPhaseText }}
                      >
                        Metaverse: OuterRingMMo <br />- Annual Party in a
                        Special Country Island [For all Holders] <br />
                        - Holders of UHD 20,000 by 2022 <br />
                      </Typography>
                      <div className='roadmapMobile'></div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>

            <Hidden mdUp>
             <div>
             <Stack spacing={2} >
               <center>
               <Grid item xs={12}>
                  <Typography
                    variant='h3'
                    color='primary'
                    align='center'
                    classes={{ root: classes.pageTitle }}
                  >
                    Road Map
                  </Typography>
                </Grid>
               </center>
                <Grid xs={12}>
                  <Stack spacing={2}>
                    <div
                    // className={classes.rightPhaseTitleWrapper}
                    >
                      {/* <div className={classes.rightPhaseLine}></div> */}
                      <Typography
                        variant='h3'
                        color='primary'
                        align='center'
                        classes={{
                          root: clsx(classes.phaseTitle, classes.phaseOne),
                        }}
                      >
                        phase 1
                      </Typography>
                    </div>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                      // align='center'
                      // classes={{ root: classes.rightPhaseText }}
                    >
                      <ul className='listStyle'>
                        <li>- Website + Social Media Launch</li>
                        <li> - NFT Minting, Wave-1 of 888 UHN </li>
                        <li>
                          - Release of Wave-2 &amp; Wave-3 of UHN Minting{' '}
                        </li>
                        <li> - UHD Launch (ETH) </li>
                        <li> - Uniswap</li>
                        <li>
                          {' '}
                          - Announce list of Merchants partnering UHC in
                          Singapore &amp; Dubai &amp; Indonesia{' '}
                        </li>
                        <li>- 50k members (Discord) </li>
                        <li> - 10k telegram (UHC) </li>
                        <li>- 1000 holders (UHD) </li>
                      </ul>
                    </Typography>
                  </Stack>
                </Grid>

                <Grid xs={12}>
                  <Stack spacing={2}>
                    <div>
                      <Typography
                        variant='h3'
                        color='primary'
                        align='center'
                        classes={{
                          root: clsx(classes.phaseTitle, classes.phaseTwo),
                        }}
                      >
                        phase 2
                      </Typography>
                    </div>

                    <Typography
                      variant='subtitle1'
                      color='primary'
                      // align='center'
                      // classes={{ root: classes.leftPhaseText }}
                    >
                      <ul className='listStyle'>
                        <li>
                          - Welcome Party In Dubai Or Maldives Or Bali Or
                          Thailand
                        </li>
                        <li>
                          {' '}
                          - Holders get to attend exclusive party in own country
                        </li>
                        <li>
                          - Onboarding more Merchants for UHC members to
                          patronize
                        </li>
                        <li>- Commencing of Frames &amp; Stake poll </li>
                        <li>- Listing of UHD on various exchanges </li>
                        <li> - Creating the DAO [Concierge APP]</li>
                        <li> - 5,000 members of UHD </li>
                      </ul>
                    </Typography>
                  </Stack>
                </Grid>
                <Grid xs={12}>
                  <Stack spacing={2}>
                    <div>
                      <Typography
                        variant='h3'
                        color='primary'
                        align='center'
                        classes={{
                          root: clsx(classes.phaseTitle, classes.phaseOne),
                        }}
                      >
                        phase 3
                      </Typography>
                    </div>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                      align='left'
                      classes={{ root: classes.rightPhaseText }}
                    >
                      <ul className='listStyle'>
                        <li> - Launch of Ultra High Metaverse [UHM]</li>
                        <li>
                          {' '}
                          - Increasing number of merchants around Asia + Dubai
                        </li>
                        <li>- Charity </li>
                        <li> - Fund Creation</li>
                        <li> - Holders of UHD 10,000 by 2022 </li>
                      </ul>
                    </Typography>
                  </Stack>
                </Grid>

                <Grid xs={12}>
                  <Stack spacing={2}>
                    <div>
                      <Typography
                        variant='h3'
                        color='primary'
                        align='center'
                        classes={{
                          root: clsx(classes.phaseTitle),
                        }}
                      >
                        phase 4
                      </Typography>
                    </div>
                    <Typography
                      variant='subtitle1'
                      color='primary'
                      // align='center'
                      classes={{ root: classes.leftPhaseText }}
                    >
                      <ul className='listStyle'>
                        <li>-Metaverse: OuterRingMMo</li>
                        <li>-Annual Party in a Special</li>
                        <li>-Country Island [For all Holders] </li>
                        <li>-Holders of UHD 20,000 by 2022 </li>
                      </ul>
                    </Typography>
                  </Stack>
                </Grid>
                <div className='roadmapMobile'></div>
              </Stack>

               </div>     
                      </Hidden>
          </div>
        </>
      </Grid>
    </Grid>
    </div>
  );
};

export default RoadMap;
