import {Link} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {Typography} from "@mui/material";
import {Grid} from "@mui/material";
import clsx from "clsx";

import Twitter from "../../assets/images/twitter.svg";
import Telegram from "../../assets/images/telegram.svg";
import Discord from "../../assets/images/discord.svg";
import Medium from "../../assets/images/medium.svg";

const useStyle = makeStyles((theme) => ({
  root: {
    background: theme.customColors.sectionBg,
    paddingTop: theme.spacing(6),
  },

  textStyles: {
    background: theme.customColors.textBg,
    textShadow: theme.customColors.textShadow,
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
  },

  pageTitle: {
    fontSize: "4rem !important",
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: 0,
    marginLeft: "auto !important",
    marginRight: "auto !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem !important",
    },
  },
  pageSubTitle: {
    fontSize: "1rem !important",
    padding: theme.spacing(1),
    marginLeft: "auto !important",
    marginRight: "auto !important",
    [theme.breakpoints.down("md")]: {
      fontSize: ".875rem !important",
    },
  },
  socialLabel: {
    fontSize: "1.5rem !important",
    textAlign: "left !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem !important",
    },
  },
  socialLogo: {
    width: "80px",
    height: "80px",
    [theme.breakpoints.down("md")]: {
      width: "60px",
      height: "60px",
    },
  },
  socialWrapper: {
    display: "flex",
    flexDirection: "column !important",
    alignItems: "center",
    justifyContent: "space-between",
  },
  socialContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  socialLink: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Social = ({image, label, link}) => {
  const classes = useStyle();
  return (
    <a href={link} target='_blank' className={classes.socialLink}>
      <img src={image} alt='social' className={classes.socialLogo} />
      <Typography
        variant='h3'
        classes={{root: clsx(classes.textStyles, classes.socialLabel)}}
      >
        {label}
      </Typography>
    </a>
  );
};

const Footer = () => {
  const classes = useStyle();
  return (
    <Grid container classes={{root: classes.root}} id='high-club-footer'>
      <Grid item xs={12}>
        <Typography
          variant='h3'
          color='primary'
          align='center'
          classes={{root: clsx(classes.textStyles, classes.pageTitle)}}
        >
          Want to learn more?
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant='h3'
          color='primary'
          align='center'
          classes={{root: clsx(classes.textStyles, classes.pageSubTitle)}}
        >
          Reach out on any of our platforms to have your questions answered!
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container classes={{root: classes.socialContainer}}>
          <Grid item xs={3} classes={{root: classes.socialWrapper}}>
            <Social
              image={Twitter}
              label='Twitter'
              link='https://twitter.com/ultrahighnft/'
            />
          </Grid>

          <Grid item xs={3} classes={{root: classes.socialWrapper}}>
            <Social
              image={Telegram}
              label='Telegram'
              link='https://t.me/ultrahighnftgroup/'
            />
          </Grid>

          <Grid item xs={3} classes={{root: classes.socialWrapper}}>
            <Social
              image={Discord}
              label='Discord'
              link='https://discord.gg/7myKFEJBbq/'
            />
          </Grid>

          <Grid item xs={3} classes={{root: classes.socialWrapper}}>
            <Social
              image={Medium}
              label='Instagram'
              link='https://instagram.com/ultrahigh.nft/'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
