import { useState, Fragment } from 'react';
import { HashLink } from 'react-router-hash-link';
import { makeStyles } from '@mui/styles';

import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { default as MenuIcon } from '@material-ui/icons/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyle = makeStyles((theme) => ({
  root: {
    zIndex: 999999999,
    position: 'fixed',
    height: '100px',
    width: '100%',
  },
  navButton: {
    color: `${theme.customColors.primary} !important`,
    fontSize: `${theme.navBar.btnFontSize} !important`,
    fontWeight: '500 !important',
    textAlign: 'center',
    marginTop: '10px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '10px',
    },
    '&:hover': {
      color: `${theme.customColors.secondary} !important`,
    },
    '&.Mui-selected': {
      color: `${theme.customColors.secondary} !important`,
    },
  },
  pageTitle: {
    // width: "max-content",
    fontSize: '2rem !important',
    background: theme.customColors.textBg,
    textShadow: theme.customColors.textShadow,
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
  },
  drawerRoot: {
    zIndex: 9999,
  },
  navButtonvip:{
    color: `${theme.customColors.primary} !important`,
    fontSize: `${theme.navBar.btnFontSize} !important`,
    fontWeight: '500 !important',
    textAlign: 'center',
    marginTop: '10px',
    [theme.breakpoints.down('md')]: {
      paddingTop:  '38px',
    },
    '&:hover': {
      color: `${theme.customColors.secondary} !important`,
    },
    '&.Mui-selected': {
      color: `${theme.customColors.secondary} !important`,
    },
   
  }
}));

const MainNav = () => {
  const classes = useStyle();

  const [value, setValue] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsDrawerOpen(open);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabItemList = [
    { label: 'vip', to: '/#high-club-home' },
    { label: 'sTORY', to: '/#high-club-about' },
    { label: 'road Map', to: '/#high-club-road-map' },
    { label: 'team', to: '/#high-club-team' },
    { label: 'mint', to: '/#high-club-works' },
    { label: 'faq', to: '/#high-club-footer' },
  ];

  const list = () => (
    <Box
      sx={{
        width: 250,
        backgroundColor: (theme) => theme.customColors.black,
      }}
      role='presentation'
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      // style={{ zIndex: 9999999, position: "relative" }}
    >
      <Divider
        style={{ borderColor: 'rgba(145, 158, 171, 0.24)', marginTop: '30px' }}
      />

      <List>
        <HashLink smooth to='/#high-club-home' key='vip'>
          <ListItem button key='vip'>
            <ListItemText
              primary='vip'
              classes={
                { root: classes.navButtonvip } 
              }
            />
          </ListItem>
        </HashLink>
        <HashLink smooth to='/#high-club-about' key='sTORY'>
          <ListItem button key='sTORY'>
            <ListItemText
              primary='sTORY'
              classes={{ root: classes.navButton }}
            />
          </ListItem>
        </HashLink>
        <HashLink smooth to='/#high-club-road-map' key='road Map'>
          <ListItem button key='road Map'>
            <ListItemText
              primary='road Map'
              classes={{ root: classes.navButton }}
            />
          </ListItem>
        </HashLink>
        <HashLink smooth to='/#high-club-team' key='team'>
          <ListItem button key='team'>
            <ListItemText
              primary='team'
              classes={{ root: classes.navButton }}
            />
          </ListItem>
        </HashLink>
        <HashLink smooth to='/#high-club-works' key='mint'>
          <ListItem button key='mint'>
            <ListItemText
              primary='mint'
              classes={{ root: classes.navButton }}
            />
          </ListItem>
        </HashLink>
        <HashLink smooth to='/#high-club-footer' key='faq'>
          <ListItem button key='faq'>
            <ListItemText primary='faq' classes={{ root: classes.navButton }} />
          </ListItem>
        </HashLink>
      </List>

      <Divider style={{ borderColor: 'rgba(145, 158, 171, 0.24)' }} />
    </Box>
  );

  const hashLinkOnClick = (value) => {
    setValue(value);
  };
  return (
    <div className={classes.root}>
      <AppBar
        position='fixed'
        sx={{
          backgroundColor: (theme) => theme.customColors.black,
          height: (theme) => theme.navBar.height,
        }}
      >
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            {/* Mobile nav */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={toggleDrawer(true)}
                color='primary'
              >
                <MenuIcon />
              </IconButton>

              {/* drawer */}
              <div className={classes.drawerRoot} id='drawer-root-wrapper'>
                <Fragment>
                  <Drawer
                    id='drawer-root'
                    anchor='left'
                    open={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                    PaperProps={{
                      sx: {
                        backgroundColor: (theme) => theme.customColors.black,
                        color: (theme) => theme.customColors.primary,
                      },
                    }}
                    BackdropProps={{ invisible: true }}
                  >
                    {list()}
                  </Drawer>
                </Fragment>
              </div>
            </Box>

            {/* Desktop nav */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant='h6'
                noWrap
                component='div'
                sx={{ mr: 2, display: { xs: 'flex', md: 'flex' } }}
              >
                <HashLink smooth to='/#high-club-home'>
                  <Button>
                    <Typography
                      variant='h4'
                      color='primary'
                      align='center'
                      classes={{ root: classes.pageTitle }}
                    >
                      Home
                    </Typography>
                  </Button>
                </HashLink>
              </Typography>

              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='main nav'
                indicatorColor='secondary'
              >
                {tabItemList.map((tab, index) => {
                  return (
                    <HashLink
                      smooth
                      to={tab.to}
                      key={tab.label}
                      onClick={() => hashLinkOnClick(index)}
                    >
                      <Tab
                        label={tab.label}
                        key={tab.label}
                        {...a11yProps(0)}
                        classes={{ root: classes.navButton }}
                      />
                    </HashLink>
                  );
                })}
              </Tabs>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default MainNav;
