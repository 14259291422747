import { HashLink, NavHashLink } from "react-router-hash-link";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";

import Grid from "@mui/material/Grid";
import UltraHigh from "../../assets/images/uh-logo.png";
import HeroImage from "../../assets/images/hero_bg.png";

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    minHeight: "100vh",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    position: "relative",
    zIndex: 9,
    background: theme.customColors.sectionBg,
  },
  pageContentRoot: {
    height: "100%",
    minHeight: "100vh",
    marginTop: "0 !important",
  },
  subSectionRoot: {
    height: "100%",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imagesRoot: {
    height: "100%",
  },
  ultraHighLogo: {
    marginBottom: theme.spacing(4),
    display: "block",
    width: "90%",
    maxWidth: "300px",
    height: "365px",
  },
  welcomeToText: {
    marginBottom: theme.spacing(2),
    display: "block",
    width: "90%",
    maxWidth: "270px",
  },
  ultraHighText: {
    marginBottom: theme.spacing(2),
    display: "block",
    width: "90%",
    maxWidth: "350px",
  },
  heroImage: {
    width: "100%",
    height: "100%",
    overflow: "hidden",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pageTitleWhite: {
    fontSize: "4rem !important",
    color: theme.customColors.primary,
    [theme.breakpoints.down("xl")]: {
      fontSize: "3rem !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem !important",
    },
  },
  pageTitle: {
    // width: "max-content",
    marginLeft: "auto !important",
    marginRight: "auto !important",
    padding: theme.spacing(2),
    fontSize: "4rem !important",
    background: theme.customColors.textBg,
    textShadow: theme.customColors.textShadow,
    paddingLeft: "22px",
    paddingRight: "22px",
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    [theme.breakpoints.down("xl")]: {
      fontSize: "2.7rem !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.7rem !important",
    },
  },
  aboutBtn: {
    fontSize: "1.9rem !important",
    color:'white !important',
    paddingLeft:'35px !important',
    paddingRight:'35px !important',
    paddingTop:'2px  !important',
    paddingBottom:'2px  !important'

    
  },
}));

const Welcome = () => {
  const classes = useStyles();

  return (
    <Grid
      classes={{
        root: classes.pageRoot,
      }}
      id="high-club-home"
    >
      <Grid
        container
        spacing={2}
        classes={{
          root: classes.pageContentRoot,
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          classes={{
            root: classes.subSectionRoot,
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            classes={{
              root: classes.imagesRoot,
            }}
          >
            <img
              src={UltraHigh}
              className={classes.ultraHighLogo}
              alt="Ultra High Logo"
            />

            <Typography
              variant="h3"
              color="primary"
              align="center"
              classes={{ root: classes.pageTitleWhite }}
            >
              Welcome to
            </Typography>

            <Typography
              variant="h3"
              color="primary"
              align="center"
              classes={{ root: classes.pageTitle }} className='welcomeText'
            >
              ultra high club
            </Typography>

            <HashLink smooth to="/#high-club-about">
              <Button variant="contained" color="secondary" classes={{ root: classes.aboutBtn }} > 
              About
                {/* <Typography
                  variant="h6"
                  color="primary"
                  align="center"
                 
                >
                 
                </Typography> */}
              </Button>
              {/* <button  > about</button> */}
            </HashLink>
          </Grid>
        </Grid>
        <Grid item xs={0} sm={6}>
          <img src={HeroImage} alt="Hero" className={classes.heroImage} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Welcome;
