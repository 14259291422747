import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  customColors: {
    primary: "#ffffff",
    secondary: "#f8df7a",
    black: "#000000cc",
    divider: "rgba(145, 158, 171, 0.24)",
    pageBg:
      "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(23,20,0,1) 55%, rgba(255,228,124,1) 100%)",
    roadMapBg:
      "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(23,20,0,1) 90%, rgba(255,228,124,1) 100%)",
    sectionBg: "linear-gradient(90deg,black 40%, #FFB341 100%)",
    sectionDivider:
      "linear-gradient(90deg, rgb(194, 143, 23), rgb(248, 223, 122), rgb(194, 143, 23), rgb(248, 223, 122), rgb(194, 143, 23), rgb(248, 223, 122), rgb(194, 143, 23), rgb(248, 223, 122), rgb(194, 143, 23), rgb(248, 223, 122), rgb(194, 143, 23), rgb(248, 223, 122), rgb(194, 143, 23), rgb(248, 223, 122))",
    textBg:
      "linear-gradient(90deg, #C28F17, #F8DF7A,#C28F17, #F8DF7A,#C28F17, #F8DF7A,#C28F17, #F8DF7A,#C28F17, #F8DF7A,#C28F17, #F8DF7A,#C28F17, #F8DF7A)",
    textShadow: "0px 0px 50px #c28f17aa",
    textBorderShadow: "0px 0px 20px #c28f17aa, inset 0px 0px 20px #c28f17aa",
    avatarBg: `linear-gradient(85deg,hsl(51deg 98% 81%) 0%,hsl(49deg 87% 76%) 8%,hsl(47deg 80% 70%) 17%,hsl(45deg 76% 65%) 25%,hsl(44deg 68% 59%) 33%, hsl(43deg 63% 54%) 42%, hsl(42deg 62% 49%) 50%, hsl(41deg 68% 45%) 58%,hsl(40deg 75% 42%) 67%,hsl(40deg 84% 39%) 75%,hsl(41deg 59% 50%) 83%,hsl(44deg 69% 61%) 92%,hsl(47deg 88% 72%) 100%)`,
  },
  navBar: {
    height: 58,
    btnFontSize: "0.875rem",
  },
  palette: {
    primary: { main: "#ffffff" },
    secondary: { main: "#F8DF7A" },
    black: { main: "#000000cc" },
    divider: "rgba(145, 158, 171, 0.24)",
  },
  components: {
    // Name of the component
    MuiTab: {
      styleOverrides: {
        // Name of the slot
        root: {
          color: "#ffffff",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          backgroundColor: "#000000cc",
        },
      },
    },
  },
});
