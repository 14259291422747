import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";

import BgImage from "../../assets/images/works-people.png";
import UltraHigh from "../../assets/images/uh-logo.png";

const useStyle = makeStyles((theme: any) => ({
  pageRoot: {
    height: "100vh",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    display:'block !important'
    
  },
  bgWrapper: {
    height: "100vh",
    width: "100%",
    background: theme.customColors.pageBg,
    position: "absolute",
    zIndex: -1,
  },
  bgInner: {
    height: "100%",
    width: "100%",
  },
  bgImage: {
    width: "100%",
   
    position: "absolute",
    bottom: 0,
    [theme.breakpoints.down('md')]:{
      objectFit:"fill",
       height: "70%",
    },
    [theme.breakpoints.down('sm')]:{
      objectFit:"fill",
       height: "70%",
    },
    [theme.breakpoints.up('xs')]:{
      objectFit:"fill",
       height: "60%",
    }
  },
  pageContent: {
    position: "absolute",
    zIndex: 1,
    paddingTop: theme.spacing(14),
  },
  ultraHighLogoWrapper: {
    position: "relative",
    zIndex: -100,
  },
  ultraHighLogo: {
    marginBottom: theme.spacing(4),
    display: "block",
    width: "90%",
    maxWidth: "300px",
    height: "365px",
    [theme.breakpoints.down("md")]: {
      width: "93%",
      height: "300px",
    },
  },
  worksImage: {
    marginTop: theme.spacing(5),
  },
  pageTitle: {
    // width: "max-content",
    marginLeft: "auto !important",
    marginRight: "auto !important",
    padding: theme.spacing(2),
    fontSize: "4rem !important",
    background: theme.customColors.textBg,
    textShadow: theme.customColors.textShadow,
    paddingLeft: "22px",
    paddingRight: "22px",
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    [theme.breakpoints.down("md")]: {
      fontSize: "3rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem !important",
    },
    
  },
}));

const Works = () => {
  const classes = useStyle();

  return (
    <Grid classes={{ root: classes.pageRoot }} id="high-club-works">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        classes={{ root: classes.bgWrapper }}
      >
        <Grid classes={{ root: classes.bgInner }}>
          <Grid item xs={12}>
            <img
              src={BgImage}
              alt="Ultra High Text"
              width="350"
              className={classes.bgImage}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        classes={{ root: classes.pageContent }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h3"
            color="primary"
            align="center"
            classes={{ root: classes.pageTitle }}
          >
            Works
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.ultraHighLogoWrapper}>
          <img
            src={UltraHigh}
            className={classes.ultraHighLogo}
            alt="Ultra High Logo"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Works;
