import {makeStyles} from "@mui/styles";
import {Grid} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import {Typography} from "@mui/material";

import PersonOne from "../../assets/images/p-1.png";
import PersonTwo from "../../assets/images/p-2.png";
import PersonThree from "../../assets/images/p-3.png";
import PersonFour from "../../assets/images/p-4.png";
import PersonFive from "../../assets/images/p-5.png";
import PersonSix from "../../assets/images/p-6.png";
import PersonSeven from "../../assets/images/p-7.png";
import PersonEight from "../../assets/images/p-8.png";
import TinyCircle from "../../assets/images/tiny-circle.png";

const useStyle = makeStyles((theme) => ({
  pageRoot: {
    height: "100%",
    minHeight: "100vh",
    width: "100%",
    background: theme.customColors.roadMapBg,
    paddingTop: theme.spacing(8),
    position: "relative",
    clear: "both",
    // marginTop: "10px !important",
  },
  pageContentRoot: {
    height: "100%",
    marginTop: "0 !important",
    padding: theme.spacing(2),
  },
  pageTitle: {
    // width: "max-content",
    marginLeft: "auto !important",
    marginRight: "auto !important",
    padding: theme.spacing(2),
    fontSize: "4rem !important",
    background: theme.customColors.textBg,
    textShadow: theme.customColors.textShadow,
    paddingLeft: "22px",
    paddingRight: "22px",
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    marginBottom: `${theme.spacing(10)} !important`,
    [theme.breakpoints.down("md")]: {
      fontSize: "3rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem !important",
    },
  },
  avatarListContainer: {
    padding: theme.spacing(2),
  },
  avatarContainer: {
    marginBottom: `${theme.spacing(10)} !important`,
    [theme.breakpoints.down("sm")]: {
      marginTop: "-40px !important",
    },
  },
  avatarWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignContent: "space-between",
    justifyContent: "center",
    position: "relative",
    marginBottom: theme.spacing(20),
    borderBottom: `8px solid ${theme.customColors.secondary}`,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(25),
    },
  },
  avatarInner: {
    position: "absolute",
    bottom: 0,
  },
  avatarImage: {
    width: "100%",
    maxWidth: "200px",
    height: "auto",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: "relative",
    zIndex: 1,
  },
  avatarBg: {
    position: "absolute",
    bottom: "10%",
    width: "100%",
    height: "100%",
    maxWidth: "170px",
    maxHeight: "200px",
    borderRadius: "50%",
    backgroundImage: theme.customColors.avatarBg,
  },
  avatarBottomCircleWrapper: {
    position: "absolute",
    top: "100%",
    left: 0,
    zIndex: 100,
  },
  avatarBottomCircle: {
    width: "80px",
    height: "80px",
  },
}));

const Avatar = ({image, popoverText}) => {
  const classes = useStyle();

  return (
    <div className={classes.avatarWrapper}>
      <div className={classes.avatarInner}>
        <Tooltip title={popoverText} placement='top'>
          <img src={image} alt='avatar' className={classes.avatarImage} />
        </Tooltip>
      </div>
      <div className={classes.avatarBg}></div>
      <div className={classes.avatarBottomCircleWrapper}>
        <img
          src={TinyCircle}
          alt='circle'
          className={classes.avatarBottomCircle}
        />
      </div>
    </div>
  );
};

const TheTeam = () => {
  const classes = useStyle();
  const avatars = [
    {image: PersonOne, name: "Nicole (Founder)"},
    {image: PersonTwo, name: "CRC (Advisor)"},
    {image: PersonThree, name: "Sean (Biz Development) "},
    {image: PersonFour, name: "Bethany (3D Designer)"},
    {image: PersonFive, name: "Ben (3D Designer)"},
    {image: PersonSix, name: "Gary (Marketing Advisor)"},
    {image: PersonSeven, name: "Josh (Discord Expert)"},
    {image: PersonEight, name: "Taylor (Blockchain Expert)"},
  ];

  return (
    <Grid
      classes={{
        root: classes.pageRoot,
      }}
      id='high-club-team'
    >
      <Grid
        container
        // spacing={2}
        classes={{
          root: classes.pageContentRoot,
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant='h3'
            color='primary'
            align='center'
            classes={{root: classes.pageTitle}}
          >
            the team
          </Typography>
        </Grid>

        <Grid
          container
          classes={{
            root: classes.avatarListContainer,
          }}
        >
          {avatars.map((avatar, index) => {
            return (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={3}
                classes={{
                  root: classes.avatarContainer,
                }}
              >
                <Avatar image={avatar.image} popoverText={avatar.name} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TheTeam;
